<!--添加题目-->
<template>
    <div class="main">
        <div class="main">
            <div style="font-size: 26px; font-weight: bold;display:flex">
                <div style="color:#C8C8C8;cursor: pointer;" @click="goback">&lt;商品分类</div>
                <div>/添加分类</div>
            </div>
            <el-row style="display: flex;flex-direction: column;">
                <el-col :span="12">
                    <div class="grid-content bg-purple" style="display: flex;align-items:center;margin: 20px 0 ;">
                        <div
                            style="flex-shrink: 0;" class="ft">分类名称</div>
                        <el-input v-model="name" placeholder="请输入名称" style="width: 60%;"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple" style="display: flex;margin: 20px 0 ;">
                        <div
                            style="flex-shrink: 0;" class="ft">分类描述</div>
                        <el-input v-model="des" placeholder="请输入描述" type="textarea" style="width: 60%;"  :rows="5"></el-input>
                    </div>
                </el-col>
                <!-- <el-col :span="10">
                    <div
                        class="grid-content bg-purple-light"
                        style="display: flex;align-items:center;"
                    >
                        <div
                             class="ft">生效时间</div>
                        <el-date-picker
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="timeList"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        ></el-date-picker>
                    </div>
                </el-col> -->
            </el-row>
            <el-row style="display: flex;flex-direction: column;">
                <!-- <el-col :span="6" style="margin: 20px 0;"><div class="grid-content bg-purple">
                    <div class="ft">问卷类型</div>
                    <el-select v-model="type" placeholder="请选择">
                            <el-option
                                v-for="item in typeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                </div></el-col>
                <el-col :span="6" style="margin:0 0 20px 0;"><div class="grid-content bg-purple-light">
                     <div class="ft">问卷对象</div>
                    <el-select v-model="pushRange" placeholder="请选择">
                            <el-option
                                v-for="item in pushRangeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                </div></el-col>
                 <el-col :span="6" style="margin:0 0 20px 0;" v-if="type==2"><div class="grid-content bg-purple-light">
                     <div class="ft" >课程包  </div>
                    <el-select v-model="courseId" placeholder="请选择">
                            <el-option
                                v-for="item in levelLists"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                </div></el-col> -->
                <!-- <el-col :span="6"><div class="grid-content bg-purple" style="display: flex;align-items: center;">
                    <div class="ft" style="flex-shrink: 0;">分类图片</div>
                     <input
                    type="file"
                    @change="uploadimg"
                    accept="image/*"
                    id="file"
                    ref="file"
                    style="color:#fff"
                />
                </div></el-col> -->
                
                <el-col :span="6"><div class="grid-content bg-purple-light">
                     <div
                        style="position:relative;width:200px"
                        alt
                    >
                        <img :src="imglist" alt style="width: 200px" />
                        <img
                        v-if="imglist!=''"
                            @click="deleteImg"
                            src="../../images/delete.png"
                            alt
                            style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px"
                        />
                    </div>
                </div></el-col>
               
            </el-row>
            <!-- <div style="display: flex;align-items:center;margin: 20px 0;"><div style="font-size: 20px;font-weight: bold;">问卷内容</div> <el-button type="primary" style="height: 34px;line-height: 10px;" @click="dialogVisible = true">添加试题</el-button></div>
            <div style="background: rgb(251, 246, 246);padding: 20px;">
                <div>
                    <div style="margin: 30px 0;">1、这是这个问卷的第一道题  (选择题)</div>
                    <div style="display: flex;">
                        <div style="margin: 0 20px;">A.第一个选项</div>
                        <div style="margin: 0 20px;">B.第二个选项</div>
                        <div style="margin: 0 20px;">C.第三个选项</div>
                        <div style="margin: 0 20px;">D.第四个选项</div>
                    </div>
                </div>

                <div>
                    <div style="margin: 30px 0;">1、这是这个问卷的第一道题  (选择题)</div>
                    <div style="display: flex;">
                        <div style="margin: 0 20px;">A.第一个选项</div>
                        <div style="margin: 0 20px;">B.第二个选项</div>
                        <div style="margin: 0 20px;">C.第三个选项</div>
                        <div style="margin: 0 20px;">D.第四个选项</div>
                    </div>
                </div>

                <div>
                    <div style="margin: 30px 0;">1、这是这个问卷的第一道题  (选择题)</div>
                    <div style="display: flex;">
                        <div style="margin: 0 20px;">A.第一个选项</div>
                        <div style="margin: 0 20px;">B.第二个选项</div>
                        <div style="margin: 0 20px;">C.第三个选项</div>
                        <div style="margin: 0 20px;">D.第四个选项</div>
                    </div>
                </div>
            </div> -->
            <div style="margin: 50px 0;display: flex;"><el-button type="primary" style="margin-right: 20px;" @click="addquestionnaire">添加分类</el-button><el-button type="primary" @click="goback">取消</el-button></div>
        </div>
        <el-dialog
            title="添加试题"
            :visible.sync="dialogVisible"
            width="80%"
            :before-close="handleClose">
                <div class="title">
            <div class="titleleft">
                <div style="align-items: center">
                    <div class="line"></div>
                    <div>题目设置</div>
                </div>
                <el-form label-position="right" style="margin-top: 40px">
                 <el-form-item label="问卷类型：">
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                 <el-form-item label="问卷题目：">
                        <el-input
                            type="textarea"
                            :rows="5"
                            v-model="textarea"
                            :style="{ width: value != 4 && value != 5 ? '342px' : '500px' }"
                        ></el-input>
                    </el-form-item>
                   
                    <el-form-item label="考试等级：">
                        <el-select v-model="levelId" placeholder="请选择">
                            <el-option
                                v-for="item in levelLists"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                   
                    <!-- <el-form-item label="操作：" v-if="value == 3">
                        <el-button @click="insterLine">插入下划线</el-button>
                    </el-form-item> -->
                    <el-form-item label="参考答案：" v-if="value == 4 || value == 5">
                        <el-input
                            type="textarea"
                            :rows="5"
                            v-model="textarea2"
                            :style="{ width: value != 4 && value != 5 ? '342px' : '500px' }"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div style="flex-direction: column" v-if="value != 4 && value != 5">
                <div style="align-items: center">
                    <div class="line"></div>
                    <div style="display: flex; align-items: baseline">
                        <div>答案设置</div>
                        <!-- <div
                            style="font-size: 14px; color: #bababa; margin-left: 11px"
                            v-if="value == 1 || value == ''"
                        >(选中则为答案，可多选)</div> -->
                    </div>
                </div>
                <div style="flex-direction: column">
                    <el-checkbox-group
                        v-model="checkList"
                        style="display: flex; flex-direction: column"
                        v-if="value == 1 || value == ''"
                    >
                        <div
                            style="display: flex; margin-top: 26px; align-items: center"
                            v-for="(item, index) of selectData"
                            :key="index"
                        >
                            <el-checkbox
                                :label="item.label"
                                style="display: flex; align-items: center"
                            >
                                <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                                <el-input
                                    v-model="item.value"
                                    placeholder="请填入选项内容"
                                    style="width: 290px"
                                ></el-input>
                            </el-checkbox>
                            <img
                                v-if="index + 1 == selectData.length && index > 2"
                                src="../../images/reduce.png"
                                alt
                                style="width: 22px; height: 23px; margin-left: 10px"
                                @click="dele(index)"
                            />
                        </div>
                        <div style="margin-top: 12px; margin-left: 200px">
                            <img
                                src="../../images/add.png"
                                alt
                                style="width: 22px; height: 23px"
                                @click="add"
                                v-if="selectData.length < 6"
                            />
                        </div>
                    </el-checkbox-group>

                    <div
                        style="display: flex; margin-top: 26px; flex-direction: column;font-weight: normal"
                        v-for="(item, index) of selectData"
                        v-if="value == 3"
                        :key="index"
                    >
                        <div style="align-items:center">
                            <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                            <el-input
                                v-model="item.value"
                                placeholder="请填入选项内容"
                                style="width: 290px"
                            ></el-input>
                            <img
                                v-if="index + 1 == selectData.length && index > 0"
                                src="../../images/reduce.png"
                                alt
                                style="width: 22px; height: 23px; margin-left: 10px"
                                @click="dele(index)"
                            />
                        </div>
                        <div
                            style="margin-top: 12px; margin-left: 200px"
                            v-if="index + 1 == selectData.length && index < 5"
                        >
                            <img
                                src="../../images/add.png"
                                alt
                                style="width: 22px; height: 23px"
                                @click="add"
                            />
                        </div>
                    </div>
                    <div
                        style="display: flex; margin-top: 26px; align-items: center"
                        v-for="(item, index) of selectData"
                        v-if="value == 2"
                        :key="index"
                    >
                        <el-radio
                            v-model="radio"
                            :label="item.label"
                            style="display: flex; align-items: center"
                        >
                            <div style="margin: 0 13px 0 27px">{{ item.name }}</div>
                        </el-radio>
                        <img
                            v-if="index + 1 == selectData.length && index > 2"
                            src="../../images/reduce.png"
                            alt
                            style="width: 22px; height: 23px"
                            @click="dele(index)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <el-form label-position="right" v-if="value == 5">
            <el-form-item label="操作：">
                <input
                    type="file"
                    @change="uploadimg"
                    accept="image/*"
                    id="file"
                    ref="file"
                    style="color:#fff"
                />
            </el-form-item>
            <el-form-item label="图片：" style="color:red" v-if="imglist.length > 0">
                <div style="display:flex">
                    <div
                        style="position:relative;width:200px"
                        v-for="(item, index) in imglist"
                        alt
                        :key="index"
                    >
                        <div
                            style="width:100%;height:100%;position:absolute;background: rgba(0,0,0,0.1);"
                        ></div>
                        <img :src="item" alt style="width: 200px" />
                        <img
                            @click="deleteImg(index)"
                            src="../../images/delete.png"
                            alt
                            style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px"
                        />
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div style="display: flex">
            <el-button type="primary" style="margin: 0 auto 65px; width: 200px" @click="submit">添加</el-button>
        </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { questiontypeList, levelList, qssaveOrUpdate, getCosCredit,addPaper,listNoSurveyList,surveyget,updatePaper,saveGoodsClass,updateGoodsClass} from "../../apis/index";
var COS = require('cos-js-sdk-v5');
export default {
    data() {
        return {
            name:'',
            des:'',
            id:'',
            courseId:'',//课程包id
            typeList:[
                 {
                    id:1,
                    name:'小程序'
                },{
                    id:2,
                    name:'课程包'
                }
            ],
            type:'',//问卷类型
            title:'',//问卷标题
            pushRangeList:[
                 {
                    id:100,
                    name:'全量'
                },{
                    id:170,
                    name:'70%'
                },
                {
                    id:150,
                    name:'50%'
                },
                {
                    id:3,
                    name:'私域'
                },
                {
                    id:13,
                    name:'公域'
                }
            ],
            pushRange:'',//推送范围
            timeList:[],//问卷生效时间
            value1: [],
            imglist: [],
            textarea2: '',
            textarea: '',
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            textarea: "",
            checkList: [],
            radio: "1",
            options: [
                {
                    id:1,
                    name:'选择题'
                },{
                    id:3,
                    name:'填空题'
                }

            ],//题目类型列表
            value: "",
            levelLists: [],//等级列表
            levelId: '',//等级值
            selectData: [
                {
                    label: "1",
                    name: "A",
                    value: "",
                },
                {
                    label: "2",
                    name: "B",
                    value: "",
                },
                {
                    label: "3",
                    name: "C",
                    value: "",
                },
            ],
        };
    },
    watch: {
        value(newdata, olddate) {
            console.log(newdata);
            if (newdata == 1) {
                this.selectData = [
                    {
                        label: "1",
                        name: "A",
                        value: "",
                    },
                    {
                        label: "2",
                        name: "B",
                        value: "",
                    },
                    {
                        label: "3",
                        name: "C",
                        value: "",
                    },
                ];
            } else if (newdata == 3) {
                this.selectData = [
                    {
                        label: "1",
                        name: "第1空",
                        value: "",
                    },
                ];
            } 
            // else if (newdata == 2) {
            //     this.selectData = [
            //         {
            //             label: "1",
            //             name: "正确",
            //         },
            //         {
            //             label: "2",
            //             name: "错误",
            //         },
            //     ];
            // }
        },
        checkList() {
            this.selectData.forEach(item => {
                item.code = 0
            })
        },
        radio() {
            this.selectData.forEach(item => {
                item.code = 0
            })
        },
    },
    created() {
        // console.log(this.$route.meta.keepAlive)
        // console.log(JSON.parse(this.$route.query.row))
        // this.questype()
        //课程包列表
        if(this.$route.query.row){
            let des=JSON.parse(this.$route.query.row)
            console.log(des)
            this.name=des.name
            this.des=des.des
            this.id=des.id
        //     this.leave().then(res=>{
        //     // this.des()
        // })
        }else{
            // this.leave()
        }
        
    },

    methods: {
        goback(){
            this.$router.go(-1)
        },
    //    async des(){//问卷详情
    //        let des=JSON.parse(this.$route.query.row)
    //        const {data}=await surveyget({id:des.id})
    //        if(data.code==200){
    //             let des=data.data
    //             this.timeList.push(des.startTime),
    //             this.timeList.push(des.endTime),
    //             this.courseId=des.courseId,
    //             this.imglist=des.picUri,
    //             this.pushRange=des.pushRange,
    //             this.title=des.title,
    //             this.type=des.type
    //        }
    //     },
       async addquestionnaire(){//添加问卷
       
       if(this.$route.query.row){//更新
        const params={
               name:this.name,
               des:this.des,
               id:this.id
            }
           const {data}=await updateGoodsClass(params)
           if(data.code==200){
                this.$message.success(data.msg);
                this.$router.go(-1)
           }else{
               this.$message.error(data.msg);
           }
       }else{//新增
           const params={
               name:this.name,
               des:this.des
            }
           const {data}=await saveGoodsClass(params)
           if(data.code==200){
                this.$message.success(data.msg);
                this.$router.go(-1)
           }else{
               this.$message.error(data.msg);
           }
       }
            
        },
        handleClose(){
            this.dialogVisible=false
        },
        deleteImg() {//删除图片
            this.imglist=''
             document.getElementById('file').value=''
        },
        insterLine() {
            this.textarea = this.textarea + '____'
        },
        uploadimg(e) {//上传图片
            if (this.$refs['file'].files[0]) {
                let selectFileobj = this.$refs['file'].files[0]
                this.upload(selectFileobj)
            }
        },
        upload(selectFileobj) {
            let that = this
            let name = selectFileobj.name.slice(0,20)
            // if (that.levelId == '') {
            //     that.$message.error('请先选择等级')
            //     document.getElementById('file').value = ''
            //     return
            // }
            let filename = 'content/resource/surveyimage/' +
                name.slice(0, name.length - 4) +
                new Date().getTime() +
                name.slice(-4);
            console.log(filename)
            var cos = new COS({
                getAuthorization: function (options, callback) {
                    getCosCredit().then(result => {
                        var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                        sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
                    })
                }
            });
            cos.options.getAuthorization('', function (obj) {
                cos.putObject({
                    Bucket: sessionStorage.getItem('bucket'), /* 必须 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: filename,              /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: selectFileobj, // 上传文件对象
                    onProgress: function (progressData) {
                        // console.log(JSON.stringify(progressData));
                        console.log(progressData)
                    }
                }, function (err, data) {
                    console.log(err || data);
                    if (data) {
                        let url = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
                        that.imglist=url
                        // that.imglist.push(url)
                        // console.log(that.imglist)
                    }
                });
            })

        },
        goback() {
            this.$router.go(-1)
        },
        async submit() {
            if (this.value == 1) {//选择题
                let selectData = this.selectData
                let checkList = this.checkList
                selectData.forEach(item => {
                    checkList.forEach(item2 => {
                        if (item.label == item2) {
                            item.code = 1
                        }
                    })
                })
                let str = ''
                const titles = selectData.map(item => {
                    if (item.code) {
                        str += item.code
                    } else {
                        str += 0
                    }
                    return item.name + item.value
                })
                let len = 8 - str.length
                for (var i = 0; i < len; i++) {
                    str += '0'
                }
                const title = titles.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    rightKey: str,
                    answer: title
                }
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 2) {//判断题
                let selectData = this.selectData
                // let checkList=this.radio
                selectData.forEach(item => {

                    if (item.label == this.radio) {
                        item.code = 1
                    }

                })
                let str = ''
                const titles = selectData.map(item => {
                    if (item.code) {
                        str += item.code
                    } else {
                        str += 0
                    }
                    return item.name + item.value
                })
                let len = 8 - str.length
                for (var i = 0; i < len; i++) {
                    str += '0'
                }
                const title = titles.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    rightKey: str,
                    // answer:title
                }
                // console.log(params)
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 3) {//填空题
                let selectData = this.selectData
                let checkList = this.checkList
                selectData.forEach(item => {
                    checkList.forEach(item2 => {
                        if (item.label == item2) {
                            item.code = 1
                        }
                    })
                })
                let str = ''
                const titles = selectData.map(item => {
                    if (item.code) {
                        str += item.code
                    } else {
                        str += 0
                    }
                    // return item.name+item.value
                    return item.value
                })
                let len = 8 - str.length
                for (var i = 0; i < len; i++) {
                    str += '0'
                }
                const title = titles.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    // rightKey:str,
                    answer: title,
                    answerCount: this.selectData.length
                }
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 4) {//主观题
                let selectData = this.selectData
                let checkList = this.checkList
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    answer: this.textarea2
                }
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 5) {//看图说话
                let selectData = this.selectData
                let checkList = this.checkList
                let resourceUri = this.imglist.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    answer: this.textarea2,
                    resourceUri: resourceUri
                }
                // console.log(params)
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            }

            //   let selectData=this.selectData
            //   let checkList=this.checkList
            //   selectData.forEach(item=>{
            //     checkList.forEach(item2=>{
            //     if(item.label==item2){
            //       item.code=1
            //     }
            //   })
            //   })
            //   let str=''
            // const titles=selectData.map(item=>{
            //     if(item.code){
            //       str+=item.code
            //     }else{
            //       str+=0
            //     }
            //     return item.name+item.value
            //   })
            //   let len=8-str.length
            //   for(var i=0;i<len;i++){
            //     str+='0'
            //   }
            //   const title=titles.join('&&&')
            //      const params={
            //         typeId:this.value,
            //         levelId:this.levelId,
            //         content:this.textarea,
            //         rightKey:str,
            //         answer:title
            //   }
            //   console.log(params)
        },

        async leave() {//课程包列表
            const { data } = await listNoSurveyList()
            if (data.code == 200) {
                this.levelLists = data.data
            } else {
                this.$message.error(data.msg)
            }
        },
        async questype() {//题目类型列表
            const { data } = await questiontypeList();
            let qslist = data.data
            this.options = qslist;

        },
        handChange(file, fileList) {
            this.fileList = fileList
        },
        handleRemove(file) {
            let uid = file.uid
            let fileList = this.fileList
            fileList.map((item, index) => {
                if (item.uid == uid) {
                    this.fileList.splice(index, 1)
                }
            })
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        add() {
            if (!this.value) {
                this.$message.error('请先选择题目类型')
                return
            }
            //添加
            if (this.value == 1) {
                let selectData = this.selectData;
                let res = selectData.some((item) => {
                    return item.name == "D";
                });
                if (res) {
                    let res = selectData.some((item) => {
                        return item.name == "E";
                    });
                    if (res) {
                        let res = selectData.some((item) => {
                            return item.name == "E";
                        });
                        if (res) {
                            let res = selectData.some((item) => {
                                return item.name == "F";
                            });
                            if (!res) {
                                this.selectData.push({
                                    label: "6",
                                    name: "F",
                                    value: "",
                                });
                            }
                        }
                    } else {
                        this.selectData.push({
                            label: "5",
                            name: "E",
                            value: "",
                        });

                        console.log(this.selectData);
                    }
                } else {
                    this.selectData.push({
                        label: "4",
                        name: "D",
                        value: "",
                    });
                    console.log(this.selectData);
                }
            } else if (this.value == 3) {
                let selectData = this.selectData;
                selectData.push({
                    label: selectData.length + 1,
                    name: `第${selectData.length + 1}空`,
                    value: "",
                })
                this.selectData = selectData
                console.log(selectData)
            }
            // else if (this.value == 3) {
            //   let selectData = this.selectData;
            //   let res = selectData.some((item) => {
            //     return item.name == "第二空";
            //   });
            //   if (res) {
            //     let res = selectData.some((item) => {
            //       return item.name == "第三空";
            //     });
            //     if (res) {
            //       let res = selectData.some((item) => {
            //         return item.name == "第四空";
            //       });
            //       if (res) {
            //         let res = selectData.some((item) => {
            //           return item.name == "第五空";
            //         });
            //         if (res) {
            //           let res = selectData.some((item) => {
            //             return item.name == "第五空";
            //           });
            //           if (res) {
            //             this.selectData.push({
            //               label: "6",
            //               name: "第六空",
            //               value: "",
            //             });
            //           }
            //         } else {
            //           this.selectData.push({
            //             label: "5",
            //             name: "第五空",
            //             value: "",
            //           });
            //         }
            //       } else {
            //         this.selectData.push({
            //           label: "4",
            //           name: "第四空",
            //           value: "",
            //         });
            //       }
            //     } else {
            //       this.selectData.push({
            //         label: "3",
            //         name: "第三空",
            //         value: "",
            //       });

            //       console.log(this.selectData);
            //     }
            //   } else {
            //     this.selectData.push({
            //       label: "2",
            //       name: "第二空",
            //       value: "",
            //     });
            //     console.log(this.selectData);
            //   }
            // }

        },
        dele(ind) {
            this.selectData.splice(ind, 1);
        },
    },
};
</script>

<style lang='less' scoped>
.main {
    background: #fff;
    margin: 19px 0;
    padding: 23px;
    border-radius: 14px;
}
.title {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    div {
        display: flex;
    }
    .line {
        width: 9px;
        height: 22px;
        border-radius: 6px;
        background: #409eff;
        margin-right: 11px;
    }
}
.title > div {
    width: 50%;
    margin: 29px 0;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    //   align-items: center;
}
.titleleft {
    display: flex;
    flex-direction: column;
}
/deep/.el-form-item__label {
    width: 100px;
}
/deep/.el-radio__label,
/deep/.el-checkbox__label {
    display: flex;
    align-items: center;
}
.ft{
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
</style>
